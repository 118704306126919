<template>
  <div v-if="goal.title">
    <div class="flex items-center justify-between md:justify-start mb-25">
      <AppButton
        type="primary"
        :size="screen.width < 769 ? 'small' : 'medium' "
        class="px-45"
        @click="onAddNewGoal"
      >
        Add new Goal
      </AppButton>
    </div>

    <!-- P R O G R E S S  S E C T I O N  D E S K T O P -->
    <div class="bg-white hidden lg:flex justify-between w-full rounded-5 p-25 mb-25">
      <div class="flex w-full max-w-7/10 gap-15 lg:gap-25">
        <div class="h-75 w-75 flex justify-center items-center bg-placebo-blue rounded-5">
          <AppIcon
            :name="categoryIconName"
            size="40"
            class="text-primary"
          />
        </div>
        <div class="goal-title-wrap flex items-start flex-col justify-between">
          <div class="flex items-center w-full justify-between">
            <div class="flex items-center w-full">
              <template v-if="showChangeName">
                <AppInput
                  v-model="v$.goalName.$model"
                  name="goal"
                  class="max-w-280 w-full mr-20"
                  maxlength="140"
                  :error="errorMessages.goalName"
                  @focus="clearErrorField('goalName')"
                />
                <AppButton
                  type="primary"
                  size="mini"
                  class="mr-10 px-14 lg:px-24"
                  @click="saveGoalName"
                >
                  Save
                </AppButton>
                <AppButton
                  class="px-14 lg:px-24"
                  plain
                  size="mini"
                  @click="closeChangeName"
                >
                  Cancel
                </AppButton>
              </template>
              <template v-else>
                <AppTruncatedTooltip
                  activator-classes="text-primary lg:text-xl mr-20"
                  :content="goal.title"
                />

                <!-- E D I T  G O A L  N A M E  B U T T O N -->
                <div
                  class="flex items-center justify-center min-h-40 min-w-40 bg-primary rounded-full"
                  :class="isAchived ? 'opacity-20 cursor-not-allowed' : 'cursor-pointer'"
                  @click="handleShowChangeName"
                >
                  <AppIcon
                    name="pen"
                    size="14"
                    class="text-white"
                  />
                </div>
              </template>

              <AppIcon
                name="star"
                size="26"
                :class="[
                  !goal.markedWithStarAt 
                    ? 'text-posey-blue' 
                    : 'text-liquid-gold', 
                  'cursor-pointer ml-10'
                ]"
                @click.stop="() => handleGoalPriority(goal)"
              />
            </div>
            <!-- P R O G R E S S   I N   P E R C E N T -->
            <p
              v-if="!showChangeName"
              class="text-grey-fp-70 lg:text-md whitespace-nowrap ml-5"
            >
              {{ progressValue }}% progress
            </p>
          </div>

          <!-- P R O G R E S S  L I N E -->
          <div class="w-full">
            <AppProgress
              :value="progressValue"
              :custom-line-class="[!isAchived ? 'bg-primary' : 'bg-posey-blue', 'h-16']"
            />
          </div>
        </div>
      </div>

      <div class="ml-auto flex items-center">
        <AppButton
          type="primary"
          class="py-5 lg:py-10 px-14 lg:px-24"
          @click="handleStatusGoal"
        >
          {{ isAchived ? 'Reopen Goal' : 'Mark Goal as Achieved' }}
        </AppButton>

        <AppButton
          v-if="!isAchived"
          type="error"
          plain
          class="py-5 lg:py-10 px-14 lg:px-24 w-full ml-5"
          @click="showRemoveGoalConfirmation"
        >
          Delete Goal
        </AppButton>
      </div>
    </div>

    <!-- P R O G R E S S  S E C T I O N  M O B I L E -->
    <div class="block lg:hidden bg-white w-full rounded-5 p-15 mb-25">
      <div class="flex items-center w-full mb-20 justify-between md:justify-start w-full">
        <template v-if="showChangeName">
          <div class="flex flex-col gap-10 w-full">
            <AppInput
              v-model="v$.goalName.$model"
              name="goal"
              class="w-full"
              maxlength="140"
              :error="errorMessages.goalName"
              @focus="clearErrorField('goalName')"
            />
            <div class="flex items-center">
              <AppButton
                type="primary"
                size="mini"
                class="mr-5 px-14"
                @click="saveGoalName"
              >
                Save
              </AppButton>
              <AppButton
                class="px-14 ml-5"
                plain
                size="mini"
                @click="closeChangeName"
              >
                Cancel
              </AppButton>
            </div>
          </div>
        </template>

        <template v-else>
          <p class="text-primary text-xl mr-20 truncate">{{ goal.title }}</p>

          <!-- E D I T  G O A L  B U T T O N -->
          <div class="flex items-center">
            <div
              class="flex items-center justify-center min-h-40 min-w-40 bg-primary rounded-full order-1 md:order-0"
              :class="isAchived ? 'opacity-20 cursor-not-allowed' : 'cursor-pointer'"
              @click="handleShowChangeName"
            >
              <AppIcon
                name="pen"
                size="14"
                class="text-white"
              />
            </div>
            <AppIcon
              name="star"
              size="26"
              :class="[
                !goal.markedWithStarAt 
                  ? 'text-posey-blue' 
                  : 'text-liquid-gold', 
                'cursor-pointer mr-10 md:mr-0 ml-0 md:ml-10 order-0 md:order-1'
              ]"
              @click.stop="() => handleGoalPriority(goal)"
            />
          </div>
        </template>
      </div>
      <div class="flex items-start mb-20">
        <div class="min-w-50 h-50 w-50 flex justify-center items-center bg-placebo-blue rounded-5 mr-15">
          <AppIcon
            :name="categoryIconName"
            size="40"
            class="text-primary"
          />
        </div>

        <!-- P R O G R E S S  L I N E -->
        <div class="w-full flex flex-col justify-between h-full">
          <span class="text-grey-fp-70 mb-22">
            {{ progressValue }}% progress
          </span>

          <AppProgress
            :value="progressValue"
            :custom-line-class="[!isAchived ? 'bg-primary' : 'bg-posey-blue', 'h-6']"
          />
        </div>
      </div>

      <div class="ml-auto flex items-center flex-col sm:flex-row gap-10 sm:gap-0">
        <AppButton
          type="primary"
          size="small"
          class="py-10 px-20 w-full sm:w-auto"
          @click="handleStatusGoal"
        >
          {{ isAchived ? 'Reopen Goal' : 'Mark Goal as Achieved' }}
        </AppButton>

        <AppButton
          v-if="!isAchived"
          type="error"
          plain
          size="small"
          class="py-10 px-20 w-full ml-5 sm:w-auto"
          @click="showRemoveGoalConfirmation"
        >
          Delete Goal
        </AppButton>
      </div>
    </div>

    <Banner class="mb-25" />

    <!-- T A S K   S E C T I O N -->
    <div class="bg-white rounded-5 p-25 mb-25">
      <div
        v-if="tasksList.length"
        class="pb-25 border-b border-grey-fp-10"
      >
        <div class="flex items-center gap-15 mb-15">
          <AppIcon
            name="tasks"
            size="28"
            class="text-success"
          />
          <span class="text-xl text-success">Tasks</span>
        </div>
        <div class="flex items cetner gap-2 border-b border-grey-fp-10 pb-25">
          <AppBadge
            icon-name="clipboard-check"
            :icon-size="10"
            custom-classes="bg-green-fp-10 inline-flex"
            :content="`${tasksList.length} tasks`"
            class="text-success mr-15 text-sm"
          />
          <span class="text-grey-fp-50 text-sm">
            Tasks Done <strong>{{ completedTasksLength }}</strong>/{{ tasksList.length }}
          </span>
        </div>

        <!-- T A S K S  L I S T -->
        <div class="mt-25 max-h-350 overflow-y-auto">
          <TaskRow
            v-for="item of tasksList"
            :key="item.id"
            :task="item"
            :disabled="isAchived"
            show-actions
            class="pr-10"
            @update-status="onTaskStatusUpdate"
            @update="onTaskUpdate"
            @delete="onTaskDelete"
          />
        </div>
      </div>
      <div
        v-else
        class="pb-25 border-b border-grey-fp-10"
      >
        <p class="text-xl italic text-grey-fp-80 text-center">No tasks yet</p>
      </div>

      <!-- C R E A T E   N E W   T A S K   B U T T O N  -->
      <AppButton
        v-if="!isAchived"
        type="success"
        class="mt-25 px-24"
        size="mini"
        icon-size="16"
        icon="plus"
        @click="onTaskCreate"
      >
        Create New Task
      </AppButton>
    </div>

    <!-- A C T I V I T I E S   S E C T I O N -->
    <RegistrationBanner
      v-if="showBanner"
      class="mb-25"
      @close="hideBanner"
    />

    <!-- A C T I V I T I E S   D E S K T O P -->
    <div class="hidden lg:block">
      <UpcomingActivities
        :activities-list="upcomingActivitiesList"
        :headers="tableHeaders"
        title="Upcoming Activities"
        class="mb-25"
        :external-link="externalLink"
        :open-modal="openRegisterModal"
        @browse="handleBrowsing"
        @confirm="confirmRegistration"
        @discard="discardRegistration"
        @register="handleRegistration"
      >
        <!-- P A G I N A T I O N -->
        <template
          v-if="upcomingActivitiesList.length"
          #pagination
        >
          <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
            <PaginationCounter
              :offset="exploreActivitiesOffset"
              :limit="ACTIVITIES_PAGINATION_LIMIT"
              :total="withoutRegistrationCount"
              class="text-posey-blue"
            />

            <v-pagination
              :page-size="ACTIVITIES_PAGINATION_LIMIT"
              layout="prev, pager, next"
              hide-on-single-page
              :total="withoutRegistrationCount"
              @current-change="(val) => handlePageChanged('exploreActivitiesPage', val)"
            />
          </div>
        </template>
      </UpcomingActivities>

      <RegisteredActivities
        :activities-list="registeredActivitiesList"
        :headers="tableHeaders"
        title="You Are Signed Up For The Following Activities"
        :external-link="externalLink"
        :open-modal="openCancellationModal"
        @browse="handleBrowsing"
        @cancel="handleCancellation"
        @discard="discardCancellation"
        @confirm="confirmCancellation"
      >
        <!-- P A G I N A T I O N -->
        <template
          v-if="registeredActivitiesList.length"
          #pagination
        >
          <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
            <PaginationCounter
              :offset="myActivitiesOffset"
              :limit="ACTIVITIES_PAGINATION_LIMIT"
              :total="registeredCount"
              class="text-posey-blue"
            />

            <v-pagination
              :page-size="ACTIVITIES_PAGINATION_LIMIT"
              layout="prev, pager, next"
              hide-on-single-page
              :total="registeredCount"
              @current-change="(val) => handlePageChanged('myActivitiesPage', val)"
            />
          </div>
        </template>
      </RegisteredActivities>
    </div>

    <!-- A C T I V I T I E S   M O B I L E -->
    <div class="block lg:hidden">
      <AppTabs
        v-model="activeTab"
        custom-tab-classes="w-full text-center"
        :items="tabs"
        class="mb-20"
      />

      <RegisteredActivities
        v-if="activeTab.value === 'registered'"
        :activities-list="registeredActivitiesList"
        :headers="tableHeaders"
        title="You Are Signed Up For The Following Learning Activities"
        :external-link="externalLink"
        :open-modal="openCancellationModal"
        @browse="handleBrowsing"
        @cancel="handleCancellation"
        @discard="discardCancellation"
        @confirm="confirmCancellation"
      >
        <template
          v-if="registeredCount > registeredActivitiesList.length"
          #pagination
        >
          <div class="flex mt-10">
            <AppButton
              type="primary"
              class="mr-10 px-20 border-primary w-full"
              plain
              @click="loadMoreActivities('registered')"
            >
              Load
              {{ registeredCount - registeredActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
                ? ACTIVITIES_PAGINATION_LIMIT
                : registeredCount - registeredActivitiesList.length }}
              more
            </AppButton>
          </div>
        </template>
      </RegisteredActivities>

      <UpcomingActivities
        v-else
        :activities-list="upcomingActivitiesList"
        :headers="tableHeaders"
        title="Upcoming Learning Activities"
        :external-link="externalLink"
        :open-modal="openRegisterModal"
        @browse="handleBrowsing"
        @confirm="confirmRegistration"
        @discard="discardRegistration"
        @register="handleRegistration"
      >
        <template
          v-if="withoutRegistrationCount > upcomingActivitiesList.length"
          #pagination
        >
          <div class="flex mt-10">
            <AppButton
              type="primary"
              class="mr-10 px-20 border-primary w-full"
              plain
              @click="loadMoreActivities('upcoming')"
            >
              Load
              {{ withoutRegistrationCount - upcomingActivitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
                ? ACTIVITIES_PAGINATION_LIMIT
                : withoutRegistrationCount - upcomingActivitiesList.length }}
              more
            </AppButton>
          </div>
        </template>
      </UpcomingActivities>
    </div>
  </div>

  <!-- R E M O V E   C O N F I R M A T I O N   M O D A L  -->
  <portal
    v-if="isOpenModal"
    to="default"
  >
    <AppModal @cancel="closeModal">
      <div
        v-if="showConfirmationRemove"
        class="w-full min-w-280 max-w-400"
      >
        <p class="text-dark-cl-20 text-md mb-8">Remove {{ removeStatus === 'goal' ? 'Goal' : 'Task' }}</p>
        <p class="text-grey-fp-60 mb-40">
          Are you sure you want to remove this {{ removeStatus === 'goal' ? 'goal' : 'task' }}?
        </p>
        <div class="flex items-center justify-end">
          <AppButton
            type="primary"
            size="mini"
            class="px-20 mr-10"
            @click="closeModal"
          >
            Cancel
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="confirmRemove"
          >
            Remove
          </AppButton>
        </div>
      </div>

      <!-- U P D A T E   T A S K   F O R M -->
      <div
        v-else
        class="w-full min-w-320 max-w-360"
      >
        <p class="text-dark-cl-20 text-md mb-28">Update Task</p>
        <CreateTaskFrom
          :data="taskForm"
          :stage="taskStage"
          @create="createNewTask"
          @update="updateTask"
          @close="closeModal"
        />
      </div>
    </AppModal>
  </portal>
</template>
<script lang="ts">
  import { defineComponent, reactive, onBeforeMount, ref, computed, toRefs } from 'vue';
  import { useRoute } from 'vue-router';
  import { useToast } from "vue-toastification";
  import { useScreen } from 'vue-screen';
  import { useVuelidate } from "@vuelidate/core";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import TaskRow from '@/components/TaskRow.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppProgress from '@/components/stateless/AppProgress.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import CreateTaskFrom from './components/CreateTaskForm.vue';
  import Banner from '@/components/Banner.vue';

  import { router, routesNames } from '@/router';
  import { IGoal, ITaskRes, TIndexedObject, ITableHeaders, IErrorFields, ITaskData } from '@/types';
  import { myGoalsService } from '@/services';
  import { updateGoalRules } from './my-goal-validation-rules';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { vuex } from '@/store';
  import { clearErrorField, handleSetErrors } from '@/core/helper-functions';
  import {
    registeredActivitiesParams,
    activitiesWithoutRegistrationsParams,
    ACTIVITIES_PAGINATION_LIMIT,
    GOAL_PARAMS
  } from '@/views/user-options/api-params';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  export default defineComponent({
    name: 'MyGoal',

    components: {
      AppButton,
      AppIcon,
      AppBadge,
      TaskRow,
      RegistrationBanner,
      UpcomingActivities,
      RegisteredActivities,
      AppTabs,
      AppProgress,
      AppTruncatedTooltip,
      AppModal,
      AppInput,
      CreateTaskFrom,
      Banner,
      PaginationCounter
    },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const screen = useScreen();
      const goalId = ref<string>('');
      const showBanner = ref<boolean>(true);
      const categoryIconName = ref<string>('');
      const goalName = ref<string>('');
      const isOpenModal = ref<boolean>(false);
      const showChangeName = ref<boolean>(false);
      const showConfirmationRemove = ref<boolean>(false);
      const showCreateTaskForm = ref<boolean>(false);
      const editTaskId = ref<number | null>(null);
      const removeStatus = ref<'goal' | 'task'>('goal');
      const taskStage = ref<'create' | 'update'>('create');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];
      const state = reactive({
        goal: {} as IGoal,
        tasksList: [] as ITaskRes[],
        activeTab: {} as TIndexedObject,
        taskForm: {} as ITaskData,
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        registeredCount,
        withoutRegistrationCount,

        upcomingActivitiesList,
        registeredActivitiesList,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: refetchActivities
      });

      const {
        goal,
        tasksList,
        activeTab,
        taskForm
      } = toRefs(state);

      const isAchived = computed<boolean>(() => goal.value.status === 'achieved');

      const errorMessages = reactive<IErrorFields>({ goalName: '' });

      const v$ = useVuelidate(updateGoalRules, { goalName });

      const completedTasksLength = computed<number>(() => {
        return tasksList.value.filter((el: ITaskRes) => el.status === 'completed').length;
      });

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const progressValue = computed<number>(() => {
        if (tasksList.value.length && !isAchived.value) {
          return Math.floor(completedTasksLength.value * 100 / tasksList.value.length);
        } else if (isAchived.value) {
          return 100;
        }

        return 0;
      });

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        refetchActivities();
      }

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (registeredActivitiesList.value.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (upcomingActivitiesList.value.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredActivitiesParams(
              goal.value.categoryId,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(
              goal.value.categoryId,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      function showRemoveGoalConfirmation() {
        isOpenModal.value = true;
        showConfirmationRemove.value = true;
      }

      function closeModal() {
        isOpenModal.value = false;
        showConfirmationRemove.value = false;
      }

      function closeChangeName() {
        showChangeName.value = false;
        goalName.value = goal.value.title;
      }

      function handleShowChangeName() {
        if (isAchived.value) return;
        showChangeName.value = true;
      }

      function hideBanner() {
        showBanner.value = false;
      }

      function onTaskUpdate(id: number) {
        taskStage.value = 'update';
        clearTaskFormFields();
        editTaskId.value = id;
        const {
          title,
          targetDate,
          description
        } = tasksList.value.find((el: any) => el.id === id) as ITaskData;

        taskForm.value = { title, targetDate: targetDate || '', description: description || '' };

        isOpenModal.value = true;
        showConfirmationRemove.value = false;
      }

      function onTaskDelete(id: number) {
        removeStatus.value = 'task';
        editTaskId.value = id;
        showRemoveGoalConfirmation();
      }

      function clearTaskFormFields() {
        taskForm.value.title = '';
        taskForm.value.description = '';
        taskForm.value.targetDate = '';
      }

      function onTaskCreate() {
        clearTaskFormFields();
        closeChangeName();
        taskStage.value = 'create';
        isOpenModal.value = true;
        showConfirmationRemove.value = false;
      }

      function onAddNewGoal() {
        router.push({ name: routesNames.addGoal });
      }

      function checkGoalId() {
        const { query } = route;

        if (!query.id) {
          router.push({ name: routesNames.root });
        } else {
          goalId.value = query.id as string;
        }
      }

      function onEditGoalName() {
        if (isAchived.value) { return; }
        showChangeName.value = true;
      }

      function changeLocalTaskStatus(res: ITaskRes) {
        tasksList.value = tasksList.value.map((el: ITaskRes) => el.id === res.id ? res : el);
      }

      function setGoal(payload: any) {
        goal.value = payload;
        categoryIconName.value = payload.category ? payload.category.iconName : 'other';
        goalName.value = payload.title;

        if (payload.tasks) {
          tasksList.value = payload.tasks;
        }
      }

      function getDataForSubmit() {
        // TODO Add type
        const data: any = {
          title: goalName.value,
          tasks: []
        };

        if (tasksList.value.length) {
          data.tasks = tasksList.value.map(({ id, title, targetDate, description }: any) => {
            return { id, title, targetDate, description };
          });
        }
        return data;
      }

      async function handleGoalPriority(payload: IGoal) {
        vuex.setLoading(true);

        return (payload.markedWithStarAt
          ? myGoalsService.removeStarMarkGoal(goalId.value)
          : myGoalsService.markWithStarGoal(goalId.value))
          .then((res: IGoal) => {
            goal.value.markedWithStarAt = res.markedWithStarAt;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function createNewTask(payload: any) {
        vuex.setLoading(true);
        const { tasks, title } = getDataForSubmit();

        return myGoalsService.updateGoal(goalId.value, { title, tasks: [...tasks, payload] })
          .then(() => {
            closeModal();
            toast.success('Task has been successfully created');

            return fetchGoal();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function confirmRemove() {
        if (removeStatus.value === 'goal') {
          deleteGoal();
        } else {
          deleteTask();
        }
      }

      async function deleteGoal() {
        vuex.setLoading(true);

        return myGoalsService.deleteGoal(goalId.value)
          .then(() => {
            toast.success('Goal has been deleted');
            router.push({ name: routesNames.myGoals });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function handleStatusGoal() {
        const status = isAchived.value ? 'open' : 'achieve';

        vuex.setLoading(true);

        return myGoalsService.updateGoalStatus(status, goalId.value)
          .then(() => fetchGoal())
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function updateTask({ data }: any) {
        vuex.setLoading(true);

        return myGoalsService.updateTask(editTaskId.value as number, data)
          .then((res: ITaskRes) => {
            tasksList.value = tasksList.value.map((el: ITaskRes) => {
              if (el.id === res.id) {
                return res;
              }

              return el;
            });

            closeModal();
            toast.success('Task has been successfully updated');
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function deleteTask() {
        vuex.setLoading(true);

        return myGoalsService.deleteTask(editTaskId.value as number)
          .then(() => {
            tasksList.value = tasksList.value.filter((el: ITaskRes) => el.id !== editTaskId.value);

            closeModal();
            removeStatus.value = 'goal';
            toast.success('Task has been successfully removed');
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function saveGoalName() {

        if (await v$.value.$validate()) {
          vuex.setLoading(true);

          return myGoalsService.updateGoal(goalId.value, getDataForSubmit())
            .then((res: any) => {
              goal.value.title = res.title;
              goalName.value = res.title;

              toast.success('Goal name has been successfully updated');
              closeChangeName();
            })
            .catch(({ response }: any) => {
              const { data } = response;
              toast.error(data.message);
            })
            .finally(() => vuex.setLoading(false));
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      async function onTaskStatusUpdate({ id, status }: ITaskRes) {
        vuex.setLoading(true);

        return myGoalsService.updateTaskStatus(status, id)
          .then((res: ITaskRes) => { changeLocalTaskStatus(res); })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchGoal() {

        return myGoalsService.fetchGoal(goalId.value, GOAL_PARAMS)
          .then((res: any) => setGoal(res));
      }

      async function fetchActivities() {

        return Promise.all([
          fetchRegisteredActivities(
            registeredActivitiesParams(goal.value.categoryId, myActivitiesOffset.value)),
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(goal.value.categoryId, exploreActivitiesOffset.value)),
        ]);
      }

      async function refetchActivities() {
        vuex.setLoading(true);

        return fetchActivities()
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return fetchGoal()
          .then(() => fetchActivities())
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));

      }

      onBeforeMount(async () => {
        checkGoalId();
        await fetchInitData();
      });

      return {
        goal,
        screen,
        tasksList,
        showBanner,
        upcomingActivitiesList,
        registeredActivitiesList,
        isOpenModal,
        showConfirmationRemove,
        tabs,
        activeTab,
        categoryIconName,
        showChangeName,
        showCreateTaskForm,
        errorMessages,
        taskForm,
        removeStatus,
        taskStage,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,
        registeredCount,
        withoutRegistrationCount,

        externalLink,
        openRegisterModal,
        openCancellationModal,
        progressValue,

        v$,

        completedTasksLength,
        isAchived,
        tableHeaders,

        onAddNewGoal,
        handleGoalPriority,
        confirmRemove,
        showRemoveGoalConfirmation,
        handleStatusGoal,
        onTaskStatusUpdate,
        onEditGoalName,
        hideBanner,
        closeModal,
        onTaskUpdate,
        onTaskDelete,
        updateTask,
        onTaskCreate,
        createNewTask,
        loadMoreActivities,
        handlePageChanged,

        handleBrowsing,
        discardCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        handleCancellation,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegistration,
        saveGoalName,
        closeChangeName,
        handleShowChangeName,
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>

<style lang="scss" scoped>
  .goal-title-wrap {
    width: calc(100% - 185px);
  }
</style>