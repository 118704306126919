
  import { defineComponent, reactive, onBeforeMount, ref, computed, toRefs } from 'vue';
  import { useRoute } from 'vue-router';
  import { useToast } from "vue-toastification";
  import { useScreen } from 'vue-screen';
  import { useVuelidate } from "@vuelidate/core";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import TaskRow from '@/components/TaskRow.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';
  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppProgress from '@/components/stateless/AppProgress.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import CreateTaskFrom from './components/CreateTaskForm.vue';
  import Banner from '@/components/Banner.vue';

  import { router, routesNames } from '@/router';
  import { IGoal, ITaskRes, TIndexedObject, ITableHeaders, IErrorFields, ITaskData } from '@/types';
  import { myGoalsService } from '@/services';
  import { updateGoalRules } from './my-goal-validation-rules';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { vuex } from '@/store';
  import { clearErrorField, handleSetErrors } from '@/core/helper-functions';
  import {
    registeredActivitiesParams,
    activitiesWithoutRegistrationsParams,
    ACTIVITIES_PAGINATION_LIMIT,
    GOAL_PARAMS
  } from '@/views/user-options/api-params';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  export default defineComponent({
    name: 'MyGoal',

    components: {
      AppButton,
      AppIcon,
      AppBadge,
      TaskRow,
      RegistrationBanner,
      UpcomingActivities,
      RegisteredActivities,
      AppTabs,
      AppProgress,
      AppTruncatedTooltip,
      AppModal,
      AppInput,
      CreateTaskFrom,
      Banner,
      PaginationCounter
    },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const screen = useScreen();
      const goalId = ref<string>('');
      const showBanner = ref<boolean>(true);
      const categoryIconName = ref<string>('');
      const goalName = ref<string>('');
      const isOpenModal = ref<boolean>(false);
      const showChangeName = ref<boolean>(false);
      const showConfirmationRemove = ref<boolean>(false);
      const showCreateTaskForm = ref<boolean>(false);
      const editTaskId = ref<number | null>(null);
      const removeStatus = ref<'goal' | 'task'>('goal');
      const taskStage = ref<'create' | 'update'>('create');
      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;
      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available' },
        { value: 'registered', label: 'Registered' },
      ];
      const state = reactive({
        goal: {} as IGoal,
        tasksList: [] as ITaskRes[],
        activeTab: {} as TIndexedObject,
        taskForm: {} as ITaskData,
      });

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        registeredCount,
        withoutRegistrationCount,

        upcomingActivitiesList,
        registeredActivitiesList,

        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration({
        updateActivitiesCb: refetchActivities
      });

      const {
        goal,
        tasksList,
        activeTab,
        taskForm
      } = toRefs(state);

      const isAchived = computed<boolean>(() => goal.value.status === 'achieved');

      const errorMessages = reactive<IErrorFields>({ goalName: '' });

      const v$ = useVuelidate(updateGoalRules, { goalName });

      const completedTasksLength = computed<number>(() => {
        return tasksList.value.filter((el: ITaskRes) => el.status === 'completed').length;
      });

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const progressValue = computed<number>(() => {
        if (tasksList.value.length && !isAchived.value) {
          return Math.floor(completedTasksLength.value * 100 / tasksList.value.length);
        } else if (isAchived.value) {
          return 100;
        }

        return 0;
      });

      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        refetchActivities();
      }

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (registeredActivitiesList.value.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (upcomingActivitiesList.value.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredActivitiesParams(
              goal.value.categoryId,
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(
              goal.value.categoryId,
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      function showRemoveGoalConfirmation() {
        isOpenModal.value = true;
        showConfirmationRemove.value = true;
      }

      function closeModal() {
        isOpenModal.value = false;
        showConfirmationRemove.value = false;
      }

      function closeChangeName() {
        showChangeName.value = false;
        goalName.value = goal.value.title;
      }

      function handleShowChangeName() {
        if (isAchived.value) return;
        showChangeName.value = true;
      }

      function hideBanner() {
        showBanner.value = false;
      }

      function onTaskUpdate(id: number) {
        taskStage.value = 'update';
        clearTaskFormFields();
        editTaskId.value = id;
        const {
          title,
          targetDate,
          description
        } = tasksList.value.find((el: any) => el.id === id) as ITaskData;

        taskForm.value = { title, targetDate: targetDate || '', description: description || '' };

        isOpenModal.value = true;
        showConfirmationRemove.value = false;
      }

      function onTaskDelete(id: number) {
        removeStatus.value = 'task';
        editTaskId.value = id;
        showRemoveGoalConfirmation();
      }

      function clearTaskFormFields() {
        taskForm.value.title = '';
        taskForm.value.description = '';
        taskForm.value.targetDate = '';
      }

      function onTaskCreate() {
        clearTaskFormFields();
        closeChangeName();
        taskStage.value = 'create';
        isOpenModal.value = true;
        showConfirmationRemove.value = false;
      }

      function onAddNewGoal() {
        router.push({ name: routesNames.addGoal });
      }

      function checkGoalId() {
        const { query } = route;

        if (!query.id) {
          router.push({ name: routesNames.root });
        } else {
          goalId.value = query.id as string;
        }
      }

      function onEditGoalName() {
        if (isAchived.value) { return; }
        showChangeName.value = true;
      }

      function changeLocalTaskStatus(res: ITaskRes) {
        tasksList.value = tasksList.value.map((el: ITaskRes) => el.id === res.id ? res : el);
      }

      function setGoal(payload: any) {
        goal.value = payload;
        categoryIconName.value = payload.category ? payload.category.iconName : 'other';
        goalName.value = payload.title;

        if (payload.tasks) {
          tasksList.value = payload.tasks;
        }
      }

      function getDataForSubmit() {
        // TODO Add type
        const data: any = {
          title: goalName.value,
          tasks: []
        };

        if (tasksList.value.length) {
          data.tasks = tasksList.value.map(({ id, title, targetDate, description }: any) => {
            return { id, title, targetDate, description };
          });
        }
        return data;
      }

      async function handleGoalPriority(payload: IGoal) {
        vuex.setLoading(true);

        return (payload.markedWithStarAt
          ? myGoalsService.removeStarMarkGoal(goalId.value)
          : myGoalsService.markWithStarGoal(goalId.value))
          .then((res: IGoal) => {
            goal.value.markedWithStarAt = res.markedWithStarAt;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function createNewTask(payload: any) {
        vuex.setLoading(true);
        const { tasks, title } = getDataForSubmit();

        return myGoalsService.updateGoal(goalId.value, { title, tasks: [...tasks, payload] })
          .then(() => {
            closeModal();
            toast.success('Task has been successfully created');

            return fetchGoal();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function confirmRemove() {
        if (removeStatus.value === 'goal') {
          deleteGoal();
        } else {
          deleteTask();
        }
      }

      async function deleteGoal() {
        vuex.setLoading(true);

        return myGoalsService.deleteGoal(goalId.value)
          .then(() => {
            toast.success('Goal has been deleted');
            router.push({ name: routesNames.myGoals });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function handleStatusGoal() {
        const status = isAchived.value ? 'open' : 'achieve';

        vuex.setLoading(true);

        return myGoalsService.updateGoalStatus(status, goalId.value)
          .then(() => fetchGoal())
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function updateTask({ data }: any) {
        vuex.setLoading(true);

        return myGoalsService.updateTask(editTaskId.value as number, data)
          .then((res: ITaskRes) => {
            tasksList.value = tasksList.value.map((el: ITaskRes) => {
              if (el.id === res.id) {
                return res;
              }

              return el;
            });

            closeModal();
            toast.success('Task has been successfully updated');
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function deleteTask() {
        vuex.setLoading(true);

        return myGoalsService.deleteTask(editTaskId.value as number)
          .then(() => {
            tasksList.value = tasksList.value.filter((el: ITaskRes) => el.id !== editTaskId.value);

            closeModal();
            removeStatus.value = 'goal';
            toast.success('Task has been successfully removed');
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function saveGoalName() {

        if (await v$.value.$validate()) {
          vuex.setLoading(true);

          return myGoalsService.updateGoal(goalId.value, getDataForSubmit())
            .then((res: any) => {
              goal.value.title = res.title;
              goalName.value = res.title;

              toast.success('Goal name has been successfully updated');
              closeChangeName();
            })
            .catch(({ response }: any) => {
              const { data } = response;
              toast.error(data.message);
            })
            .finally(() => vuex.setLoading(false));
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      async function onTaskStatusUpdate({ id, status }: ITaskRes) {
        vuex.setLoading(true);

        return myGoalsService.updateTaskStatus(status, id)
          .then((res: ITaskRes) => { changeLocalTaskStatus(res); })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchGoal() {

        return myGoalsService.fetchGoal(goalId.value, GOAL_PARAMS)
          .then((res: any) => setGoal(res));
      }

      async function fetchActivities() {

        return Promise.all([
          fetchRegisteredActivities(
            registeredActivitiesParams(goal.value.categoryId, myActivitiesOffset.value)),
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(goal.value.categoryId, exploreActivitiesOffset.value)),
        ]);
      }

      async function refetchActivities() {
        vuex.setLoading(true);

        return fetchActivities()
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return fetchGoal()
          .then(() => fetchActivities())
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));

      }

      onBeforeMount(async () => {
        checkGoalId();
        await fetchInitData();
      });

      return {
        goal,
        screen,
        tasksList,
        showBanner,
        upcomingActivitiesList,
        registeredActivitiesList,
        isOpenModal,
        showConfirmationRemove,
        tabs,
        activeTab,
        categoryIconName,
        showChangeName,
        showCreateTaskForm,
        errorMessages,
        taskForm,
        removeStatus,
        taskStage,
        ACTIVITIES_PAGINATION_LIMIT,
        myActivitiesOffset,
        exploreActivitiesOffset,
        registeredCount,
        withoutRegistrationCount,

        externalLink,
        openRegisterModal,
        openCancellationModal,
        progressValue,

        v$,

        completedTasksLength,
        isAchived,
        tableHeaders,

        onAddNewGoal,
        handleGoalPriority,
        confirmRemove,
        showRemoveGoalConfirmation,
        handleStatusGoal,
        onTaskStatusUpdate,
        onEditGoalName,
        hideBanner,
        closeModal,
        onTaskUpdate,
        onTaskDelete,
        updateTask,
        onTaskCreate,
        createNewTask,
        loadMoreActivities,
        handlePageChanged,

        handleBrowsing,
        discardCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        handleCancellation,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegistration,
        saveGoalName,
        closeChangeName,
        handleShowChangeName,
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
